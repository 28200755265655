/*
This file is just for the body layout. To make it responsive, it works with wcmc_hero_threecol.js
Which duplicates the related-content sidebar contents into an area below the fold for smaller screens
*/

// Bring in a couple files from Bootstrap so we can use the clearfix and vendor mixins (for transition, etc)
@import "../../scss/bootstrap/mixins/_clearfix";
@import "../../scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../scss/_variables";
@import "../../scss/_mixins";

/* =WCMC Hero Three Column
----------------------------------------------------------*/

// This overrides a style coming from Radix Layouts. Our gutter is a little bigger.
// Not sure this is still used?
.container .container {
  margin-left: -20px;
  margin-right: -20px;
}

.panel-hero-3col {
  @include clearfix();
  position: relative;
  
  .hero-image img {
    width: 100%;
    height: auto;
    margin: 0 0 20px;
  }

  #main-content {
    display: flex;
    flex-flow: row wrap;
    @include breakpoint($sm) {
      display: block;
    }
  }

  #related-content-sidebar {
    order: 2;
    @include breakpoint($sm) {
      width: 220px;
      margin-left: 40px;
      float: right;
    }
  }

  .information-sidebar {
    line-height: 1.7;
  }

  .pane-node-field-related-articles {
    clear: both;
  }

}


