/*
This file is just for the body layout. To make it responsive, it works with wcmc_hero_threecol.js
Which duplicates the related-content sidebar contents into an area below the fold for smaller screens
*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =WCMC Hero Three Column
----------------------------------------------------------*/
.container .container {
  margin-left: -20px;
  margin-right: -20px; }

.panel-hero-3col {
  position: relative; }
  .panel-hero-3col:before, .panel-hero-3col:after {
    content: " ";
    display: table; }
  .panel-hero-3col:after {
    clear: both; }
  .panel-hero-3col .hero-image img {
    width: 100%;
    height: auto;
    margin: 0 0 20px; }
  .panel-hero-3col #main-content {
    display: flex;
    flex-flow: row wrap; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col #main-content {
        display: block; } }
  .panel-hero-3col #related-content-sidebar {
    order: 2; }
    @media screen and (min-width: 768px) {
      .panel-hero-3col #related-content-sidebar {
        width: 220px;
        margin-left: 40px;
        float: right; } }
  .panel-hero-3col .information-sidebar {
    line-height: 1.7; }
  .panel-hero-3col .pane-node-field-related-articles {
    clear: both; }
